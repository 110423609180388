<template>
  <div>
    <div class="w-full" id="Scroll">
      <vx-card>
        <div id="data-list-list-view" class="data-list-container">
          <vs-table
            :data="Model"
            ref="refpatientSurgeriesTable"
            primary-key="id"
            >
            <div
              slot="header"
              class="flex flex-wrap-reverse items-center flex-grow justify-between"
            >
            
            </div>

            <template slot="thead">
              <vs-th >{{ $t("Surgeries") }}</vs-th>
              <vs-th >{{ $t("PreferDateFrom") }}</vs-th>
              <vs-th >{{ $t("PreferDateTo") }}</vs-th>
              <vs-th >{{ $t("SurgeryDate") }}</vs-th>
              <vs-th >{{ $t("PaymentStatus") }}</vs-th>
              <vs-th >{{ $t("Status") }}</vs-th>

              <vs-th >{{ $t("Doctors") }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-tr class="cursor-pointer hover:text-primary">
                  <vs-td>
                    <b-link
                    class="font-weight-bold"
                    >
                    <div @click="GoTOSurgeryProfile(tr.ID)">
                      <p v-if="tr.HospitalSurgery.Surgery">
                        <u class="text-primary" style="cursor: pointer;">
                          #{{ tr.HospitalSurgery.Surgery.Name }}</u
                          >
                        </p>
                      </div>
                    </b-link>
                  </vs-td>
                  <vs-td>
                   
                    <p v-if="tr.SurgeryDateFrom">
                      {{ tr.SurgeryDateFrom.split("T")[0] }}
                    </p>
                  </vs-td>
                  
                  <vs-td>
                   <p v-if="tr.SurgeryDateTo">
                     {{ tr.SurgeryDateTo.split("T")[0] }}
                   </p>
                 </vs-td>

                 <vs-td>
                   <p v-if="tr.SurgeryDate">
                     {{ tr.SurgeryDate.split("T")[0] }}
                   </p>
                   <p v-else>{{$t("NotSetYet")}}</p>
                 </vs-td>


                 <vs-td>
                   <p v-if="tr.PaymentStatus">
                     {{ tr.PaymentStatus.Name }}
                   </p>
                 </vs-td>

                 <vs-td>
                   <p v-if="tr.Status">
                     {{ tr.Status.Name }}
                   </p>
                 </vs-td>

                 <vs-td>
                  <b-media vertical-align="center" v-if="tr.Doctor">
                    <template #aside>
                      <imageLazy
                      :imageStyle="{ 'border-radius': '15px','width': '30px','height':'30px'}"
                      :imageClass="'rounded-full'"
                      :src="(baseURL + tr.Doctor.ProfileImagePath)"
                      placeHolderType="doctor"
                     />
                  </template>
                  <p v-if="tr.Doctor" class="font-weight-bold d-block text-nowrap">
                    {{( tr.DoctorName )}}
                  </p>
                  <p v-else class="font-weight-bold d-block text-nowrap">
                    {{$t("NotSetYet")}}
                  </p>
                </b-media>
                 </vs-td>
              <vs-td>
                  
    </vs-td>
               
  </vs-tr>
              
</tbody>
           
</template>
          </vs-table>
        
        </div>

      </vx-card>

    </div>
  
    
  </div>

</template>

<script>
import imageLazy from "@/components/image_lazy.vue";
import { domain } from "@/gloabelConstant.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import { BAvatar, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BMedia, BPagination, BRow, BTable, BTooltip } from 'bootstrap-vue';

export default {
  data() {
    return {
      tableColumns:[
         { key: 'Surgeries', label: this.$t("Surgeries"), sortable: true },
         { key: 'PreferDateFrom', label: this.$t("PreferDateFrom"), sortable: true },
         { key: 'PreferDateTo', label: this.$t("PreferDateTo"), sortable: true },
         { key: 'SurgeryDate', label: this.$t("SurgeryDate"), sortable: true },
         { key: 'PaymentStatus', label: this.$t("PaymentStatus"), sortable: true },
         { key: 'Status', label: this.$t("Status"), sortable: true },
         { key: 'Doctor', label: this.$t("Doctor"), sortable: true },
        
        ],
      baseURL: domain,
      //Model: []
    };
  },
  components:{
    BAvatar, BBadge, BButton, BCard, BCol, BDropdown, BDropdownItem, BFormInput, BLink, BMedia, BPagination, BRow, BTable, BTooltip,
    imageLazy
  },

  computed: {
    // PatientReservationSurgeries() {
    //   return this.$store.state.patientList.PatientReservationSurgeries;
    // }
  },
   props: {
    Model: {
      type: Array,
      required: true,
    },

  },
  methods: {

    // getPatientReservationSurgery(id) {
    //   var model = {};
    //   model.PatientId = id;
    //   model.statusIds = [3, 5]; //confirmed
    //   this.$vs.loading();

    //   this.$store
    //     .dispatch("patientList/getPatientReservationSurgery", model)
    //     .then(() => {
    //       this.$vs.loading.close();
    //             if(this.Model==null||this.Model.length==0){
    //          this.$vs.notify({
    //       title: this.$t("NoData"),
    //       text: this.$t("NoDataToshow"),
    //       iconPack: "feather",
    //       icon: "icon-alert-circle",
    //       color: "warning"
    //     });
    //     }
    //     })
    //     .catch(() => {
    //       this.$vs.loading.close();
    //     });
    // }
    // ,
        GoTOSurgeryProfile(ID) {
      this.$router.push({ name: "Surgeryprofile", params: { ID: ID } });
    }
  },
  created() {
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    // this.$store
    //     .commit("patientList/SET_PatientReservationSurgeries", [])
    // // const ID = this.$route.params.ID;
    // const ID = this.$store.state.AppActiveUser.Patient.ID;
    // if (ID != undefined) {
    //   debugger;
    //   this.getPatientReservationSurgery(ID);
    // }
  }
};
</script>
<style>


#RSurgery table {
  border-collapse: collapse;
  border-radius: 36px !important;
  -moz-border-radius: 36px;
}

#RSurgery td,
#RSurgery th {
  border-left: solid #00000029 1px;
  border-top: solid #00000029 1px;
}

#RSurgery th {
  border-top: none;
}

#RSurgery th:last-child {
  border-top-right-radius: 36px;
}
#RSurgery th:first-child {
  border-top-left-radius: 36px;
  border-left: none;
  border-right: none;
}


#RSurgery td:first-child {
  border-left: none;
}
#RSession th,
tr {
  height: 5rem;
}

.scroll{
   overflow-x:auto !important
}
</style>

<style lang="scss">
.onlineDot {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}
.offlineDot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
#page-user-list {
  .user-list-filters {
    height: 10px;

    .vs__actions {
      position: absolute;
      right: 0;
      top: 20%;
      height: 100px;
      transform: translateY(-58%);
    }
  }
}

#instant-search-demo {
  .header {
    .filters-label {
      width: calc(260px + 2.4rem);
    }
  }

  #content-container {
    .vs-sidebar {
      position: relative;
      float: left;
    }
  }

  .search-input-right-aligned-icon {
    padding: 1rem 1.5rem;
  }

  .price-slider {
    max-width: 80%;
  }

  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
    min-width: 50%;
  }

  .item-view-secondary-action-btn {
    min-width: 50%;
  }
}

.theme-dark {
  #instant-search-demo {
    #content-container {
      .vs-sidebar {
        background-color: #10163a;
      }
    }
  }
}

.autocomplete {
  position: relative;
  width: 100%;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  overflow: auto;
  width: 100%;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #4ab6a2;
  color: white;
}

@media (min-width: 992px) {
  .vs-sidebar-rounded {
    .vs-sidebar {
      border-radius: 0.5rem;
    }

    .vs-sidebar--items {
      border-radius: 0.5rem;
    }
  }
}

@media (max-width: 992px) {
  #content-container {
    .vs-sidebar {
      position: fixed !important;
      float: left !important;
    }
  }
}

.grid-view-item {
  // height: 450px;

  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: 0.35s;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

    .grid-view-img {
      opacity: 0.9;
    }
  }

  button.page-link {
    display: inline-block;
  }

  button.page-link {
    font-size: 20px;
    color: #29b3ed;
    font-weight: 500;
  }

  .offset {
    width: 500px !important;
    margin: 20px auto;
  }
}

#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
.vs-sidebar.vs-sidebar-parent {
  max-height: 1500px !important;
}
</style>


